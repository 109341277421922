import request from '@/utils/request'


// 查询歌曲技巧普列表
export function listSkill(query) {
    return request({
        url: '/music/music-skill/list',
        method: 'get',
        params: query
    })
}

// 查询歌曲技巧普分页
export function pageSkill(query) {
    return request({
        url: '/music/music-skill/page',
        method: 'get',
        params: query
    })
}

// 查询歌曲技巧普详细
export function getSkill(data) {
    return request({
        url: '/music/music-skill/detail',
        method: 'get',
        params: data
    })
}

// 新增歌曲技巧普
export function addSkill(data) {
    return request({
        url: '/music/music-skill/add',
        method: 'post',
        data: data
    })
}

// 修改歌曲技巧普
export function updateSkill(data) {
    return request({
        url: '/music/music-skill/edit',
        method: 'post',
        data: data
    })
}

// 删除歌曲技巧普
export function delSkill(data) {
    return request({
        url: '/music/music-skill/delete',
        method: 'post',
        data: data
    })
}
